import styled from 'styled-components';
import {device} from '../Common/device';
import {Ctabtn,SectionHeading} from '../Common/common.style'
import {Row} from '@bootstrap-styled/v4';

export const ContactWrapper = styled.section`

    @media ${device.tablet} {
        
    }
`;

export const CustomRow = styled(Row)`
    align-items:center;
`;

export const ContactFormHeading = styled(SectionHeading)`

`;

export const Form = styled.form`
    margin-bottom:0px;
`;

export const FormGroup = styled.div`
    margin-bottom:30px;

    .react-datepicker-wrapper{
        width:100%;
    }
`;

export const FormGroupSubmit = styled.div`
    margin-bottom:0px;

    .react-datepicker-wrapper{
        width:100%;
    }
`;

export const NameInput = styled.input`
    width: 100%;
    outline:0;
    background:#bbc3c8;
    padding: 10px 20px;
    border: none;
    border-radius:2px;
    font-size:14px;
    ::placeholder
    {
        font-size:14px;
    }
`;

export const ContactFormSpanErr = styled.span`
    color:red;
    font-size:14px;
`;

export const TextCenter = styled.div`
    text-align:center;
`;

export const SubmitBtn = styled(Ctabtn)`
    padding: 15px 40px;
`;

export const ContactFormSpanSuccess = styled.span`
    color:green;
    font-size:14px;
`;
