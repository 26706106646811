import styled from 'styled-components';
import { Commonh5 } from '../Common/common.style';
import { device } from '../Common/device'
import { SocialFacebook } from '@styled-icons/typicons/SocialFacebook'
import { SocialInstagram } from '@styled-icons/typicons/SocialInstagram'

export const FooterSection = styled.footer`
    background-color:#30416B;
    position:relative;
    padding:60px 0px 50px;

    @media ${device.tablet} {
        padding: 60px 10px 40px;
    }


`;

export const FooterInner = styled.div`
`;

export const FooterCol = styled.div`
    text-align:left;

    ul{
        margin-left:0px;
        margin-bottom:0px;
    }

    @media ${device.tablet} {
        text-align:center;
        padding:15px 0px;
    }
`;

export const FooterLogo = styled.img`
    max-width: 280px;

    @media ${device.laptop} {
        max-width: 140px;
    }

    @media ${device.tablet} {
        max-width: 170px;
    }
`;

export const FooterTitle = styled(Commonh5)`
    font-weight:500;
    font-size:26px;
    margin-bottom:25px;
    text-align:left;
    color:#fff;

    @media ${device.tablet} {
        font-size:24px;
        display: none;
    }
`;

export const FooterUl = styled.ul`
    
`;

export const FooterLi = styled.li`
    list-style:none;

    :last-child{
        margin-bottom:0px;
    }
`;

export const FooterMenu = styled.a`
    color:#fff;
    text-decoration:none;
    font-size:20px;
    :hover
    {
        font-weight:500;
        text-decoration:none;
    }
`;

export const FooterSocial = styled.a`
    margin:10px;
`;

export const InstaIcon = styled(SocialInstagram)`
    width: 50px;
    color: #fff;
    background: rgba(255, 255, 255, .3);
    padding: 6px;
    border-radius:100%;
    transition:all .5s;
    :hover{
        background:#ed008d;
        color:#fff;
    }
`;

export const FbIcon = styled(SocialFacebook)`
    width: 50px;
    color: #fff;
    background: rgba(255, 255, 255, .3);
    border-radius:100%;
    padding: 6px;
    transition:all .5s;
    :hover{
        background:#3b5999;
        color:#fff;
    }
`;

export const FooterPara = styled.p`
    margin:15px;
    font-size:15px;
    color:#fff;
`;