import { Api } from "../config/axios";

/**
 * ? Envia um email para a equipe de suporte.
 * ---
 * @param data (object) objeto com os dados ne necessários para o envio.
 * ---
 * @return void.
 */
export const mailTo = async (data) => {

    const response = await Api.post(`/mailTo`, data);

    console.log(response)

}
